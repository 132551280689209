<template>
  <div>
    <p class="title">
      <el-button :type="isPrimary=='1'?'primary':''" @click="isPrimary='1'">我订阅的人</el-button>
      <el-button :type="isPrimary=='2'?'primary':''" @click="isPrimary='2'">订阅我的人</el-button>
      <el-button :type="isPrimary=='3'?'primary':''" @click="isPrimary='3'">我订阅的邻域</el-button>
      <el-button :type="isPrimary=='4'?'primary':''" @click="isPrimary='4'">我订阅的资源</el-button>
      <el-button :type="isPrimary=='5'?'primary':''" @click="isPrimary='5'">我订阅的主题</el-button>
    </p>
    <!-- 信息展示区 -->
    <div class="container">
      <!-- 我订阅的人 -->
      <div class="contain" v-if="isPrimary=='1'">
        <ul ref="tip">
          <li v-for="(item, index) in subsOther" :key="index">
            <p class="font14 sort text-right">资源交易</p>
            <img :src="item.img" />
            <div class="info">
              <span class="name font14 color-black">{{ item.name }}</span>
              <span class="quantity"
                >发表了 <font>{{ item.uploadNum }}</font> 篇资源</span
              >
              <span class="type"
                >订阅方式: <font>{{ item.type }}</font>
              </span>
            </div>
            <div class="info-r info">
              <span class="color-black"
                >被<font color="black">{{ item.concernNum }}</font
                >人关注</span
              >
              <span
                class="cancel-sub"
                @click="cancelSubs(item.subscribeuserinfo_id)"
                >取消订阅</span
              >
              <span style="position: absolute;right: 0;bottom: 30px; top: 50px;"
                class="cancel-sub"
                @click="dialogVisible1 = true"
                >查看合约</span
              >
              <span class="term"
                >有效期: <font>{{ item.expires }}</font>
              </span>
            </div>
            <el-dialog
              title="个性订阅合约"
              :visible.sync="dialogVisible1"
              width="50%"
              :before-close="handleClose"
            >
              <p>
                个性化订阅支持两种订阅方式，基本订阅+折扣和一次性付费。被订阅对象为上传资源者，基本订阅+折扣指订阅者支付基本费用后，下载被订阅者所上传的资源将可以在原价的基础上享受相应的折扣；而一次性付费指订阅者支付一定费用后，将可以免费下载被订阅者所上传的所有资源。
              </p>
              <p>
                订阅者可以在个人中心-我的订阅页面，查看自己订阅的用户。订阅者可以单方面取消订阅，取消订阅后，将不在享受订阅的所有优惠，所有资源恢复原价；同时，订阅时所支付的费用将不会退还；
              </p>
              <p>订阅信息：</p>
              <p>被订阅作者用户名: {{ item.name }}</p>
              <p>订阅类型: {{ item.type }}</p>
              <p>过期时间: {{ item.expires }}</p>
            </el-dialog>
          </li>
          <!-- <li>
                <p class="font14 sort text-right">资源交易</p>
                <img src="https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1570818055,3685593169&fm=26&gp=0.jpg" />
                <div class="info">
                    <span class="name font14 color-black">name</span>
                    <span class="quantity">发表了 <font>3</font> 篇资源</span>
                    <span class="term">订阅有效期: <font>2020-10-20</font> </span>
                    <span class="type">订阅有方式: <font>基本费用+5折</font> </span>
                </div>
                <div class="info-r info">
                    <span class="color-black">被<font color="black">2</font>人关注</span>
                    <span class="cancel-sub">取消订阅</span>
                </div>
            </li> -->
        </ul>

        <!-- 取消我订阅的弹窗 -->
        <el-dialog
          title="取消订阅"
          :center="true"
          :visible.sync="dialogVisibleSubs"
          width="30%"
        >
          <span
            >您已订阅该用户,如取消订阅,费用不予退还,同时不再享受订阅优惠。</span
          >
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleSubs = false">取 消</el-button>
            <el-button
              :loading="cancelSubsLoading"
              type="primary"
              @click="confirmCancelSubs"
              >坚持放弃</el-button
            >
          </span>
        </el-dialog>
      </div>


      <!-- 订阅我的人 -->
      <div class="contain" v-if="isPrimary=='2'">
        <ul ref="tip2">
          <li v-for="(item, index) in subsMe" :key="index">
            <p class="font14 sort text-right">资源交易</p>
            <img :src="item.img" />
            <div class="info">
              <span class="name font14 color-black">{{ item.name }}</span>
              <span class="quantity"
                >发表了 <font>{{ item.uploadNum }}</font> 篇资源</span
              >
              <!-- <span class="term">有效期: <font>{{item.expires}}</font> </span> -->
              <span class="type"
                >订阅方式: <font>{{ item.type }}</font>
              </span>
            </div>
            <div class="info-r info">
              <span class="color-black"
                >被<font color="black">{{ item.concernNum }}</font
                >人关注</span
              >
              <div>
                <span
                  >有效期: <font>{{ item.expires }}</font>
                </span>
              </div>
            </div>
          </li>
          <!-- <li>
              <p class="font14 sort text-right">资源交易</p>
              <img src="https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1570818055,3685593169&fm=26&gp=0.jpg" />
              <div class="info">
                  <span class="name font14 color-black">name</span>
                  <span class="quantity">发表了 <font>3</font> 篇资源</span>
                  <span class="term">订阅有效期: <font>2020-10-20</font> </span>
                  <span class="type">订阅有方式: <font>基本费用+5折</font> </span>
              </div>
              <div class="info-r info">
                  <span class="color-black">被<font color="black">2</font>人关注</span>
                  
              </div>
          </li> -->
        </ul>
      </div>

      <!-- 订阅主题 -->
      <div class="contain" v-if="isPrimary=='3'">
        <ul ref="tip2">
          <li class="theme_list_item" v-for="(item,i) of ztList" :key="i">
            <img :src="item.img" class="list_item_img">
            <div class="list_item_right">
              <div class="item_right_title">
                <span @click="tapZt(item)">{{item.title}}</span>
                <div @click.stop="tapQxDy(item,'myDyZt')">取消订阅</div>
              </div>
              <div class="item_right_comment">
                {{item.content}}
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- 订阅资源 -->
      <div class="contain" v-if="isPrimary=='4'">
        <ul ref="tip2">
          <li class="dyZyList"  v-for="(item, index) in zyList" :key="index" @click="tapZy(item)">
            <img :src="item.img" class="dyZyList_item_img">
            <div class="dyZyList_item_right">
              <div class="dyZyList_item_right_title">
                <span>{{item.name}}</span>
                <div @click.stop="tapQxDy(item,'myDyZy')">取消订阅</div>
              </div>
              <div class="dyZyList_item_right_comment">
                资源等级：{{item.level}} <span style="margin-left:40px;">下载数{{item.download}}</span>
              </div>
            </div>
          </li>
        </ul>
        <el-pagination @current-change="getList"
          background
          layout="prev, pager, next"
          :page-size="limit"
          :total="count">
        </el-pagination>
      </div>


      <!-- 关键词资源 -->
      <div class="contain" v-if="isPrimary=='5'">
        <ul ref="tip2">
          <li class="dyZyList fxSb"  v-for="(item, index) in lableList" :key="index">
            <span style="font-size:16px;" @click="tapGjz(item)">主题：{{item.keyword}}</span>
            <div class="qxdyBtn" @click="tapQxDy(item,'myDyGjz')">取消订阅</div>
          </li>
        </ul>
        <el-pagination @current-change="getList"
          background
          layout="prev, pager, next"
          :page-size="limitl"
          :total="countl">
        </el-pagination>
      </div>


    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import api from '@/dev/api.ts'
import { INSERT, QUERY, DELETE, QUERYED, UPDATES } from "@/services/dao.js";
import { showTimeFormat, showTimeFormat2 } from "@/js/common";
export default {
  data() {
    return {
      isPrimary: "1",
      dialogVisibleSubs: false,
      cancelSubsLoading: false,
      dialogVisible1: false,
      cancelUserID: "", // 取消订阅id
      subsMe: [
        {
          // 订阅我的
          name: "",
          img: "",
          concernNum: "",
          expires: "",
          type: "",
          uploadNum: "0",
        },
      ],
      subsOther: [
        {
          // 我订阅的
          name: "",
          img: "",
          concernNum: "",
          expires: "",
          type: "",
          uploadNum: "",
        },
      ],

      ztList:[],  //主题列表
      zyList:[],   //资源列表
      lableList:[],  //关键词订阅
      page:1,
      limit:10,
      count:0,
      pagel:1,
      limitl:10,
      countl:0,
    };
  },
  created() {
    this.mySubc();
    this.mySubced();
    this.myDyZt();
    this.myDyZy();
    this.myDyGjz()
    // this.$nextTick(this.mySubc)
  },
  methods: {
    async mySubc() {
      this.subsOther = [];
      // 我订阅的人
      let resp = await QUERYED(
        "post",
        "",
        "SubscribeMe(where:{subscribers_user_id:{_eq:" +
          this.$store.state.userinfo.id +
          "},state:{_eq:" +
          0 +
          "},_not:{id:{_eq:" +
          this.$store.state.userinfo.id +
          "}}}) {id img name concernNum expires subscribe_type state subscribeuserinfo_id}"
      );
      // if (resp.data.SubscribeMe.length == 0) {
      //   this.$refs.tip.style.height = 50 + "px";
      //   this.$refs.tip.innerText = "暂 无";
      //   this.$refs.tip.style.color = "#c7c7c7";
      //   return;
      // }
      for (let i = 0; i < resp.data.SubscribeMe.length; i++) {
        // 查询上传资源数
        let resp2 = await QUERYED(
          "post",
          "",
          "ZYDocument_all_aggregate(where:{user:{_eq:" +
            resp.data.SubscribeMe[i].id +
            "}}) {aggregate{ count }}"
        );
        this.subsOther.push({
          id: resp.data.SubscribeMe[i].id,
          name: resp.data.SubscribeMe[i].name,
          img: this.api.LoginURL.concat(resp.data.SubscribeMe[i].img),
          uploadNum: resp2.data.ZYDocument_all_aggregate.aggregate.count || 0,
          expires: showTimeFormat2(resp.data.SubscribeMe[i].expires),
          type:
            resp.data.SubscribeMe[i].subscribe_type.search("一次性") > -1
              ? resp.data.SubscribeMe[i].subscribe_type.split(" +")[0]
              : resp.data.SubscribeMe[i].subscribe_type,
          concernNum: resp.data.SubscribeMe[i].concernNum,
          subscribeuserinfo_id: resp.data.SubscribeMe[i].subscribeuserinfo_id,
        });
      }
    },


    tapGjz(item){
      this.$router.push({path:'/Resource_theme?name='+item.keyword+"&type=0"})
    },
    tapZy(item){

      this.$router.push({path:'/Resource_details?id='+item.id})
    },

    tapZt(item){
      console.log(item,123);
      this.$router.push({path:'/Resource_theme?name='+item.title})
    },

    async mySubced() {
      this.subsMe = [];
      // 订阅我的人
      let resp = await QUERYED(
        "post",
        "",
        "SubscribeMe(where:{subscribed_user_id:{_eq:" +
          this.$store.state.userinfo.id +
          "},state:{_eq:" +
          0 +
          "},_not:{id:{_eq:" +
          this.$store.state.userinfo.id +
          "}}}) {id img name concernNum expires subscribe_type state}"
      );
      // if (resp.data.SubscribeMe.length == 0) {
      //   this.$refs.tip2.style.height = 50 + "px";
      //   this.$refs.tip2.innerText = "暂 无";
      //   this.$refs.tip2.style.color = "#c7c7c7";
      //   return;
      // }
      for (let i = 0; i < resp.data.SubscribeMe.length; i++) {
        // 查询上传资源数
        let resp2 = await QUERYED(
          "post",
          "",
          "ZYDocument_all_aggregate(where:{user:{_eq:" +
            resp.data.SubscribeMe[i].id +
            "}}) {aggregate{ count }}"
        );
        this.subsMe.push({
          id: resp.data.SubscribeMe[i].id,
          name: resp.data.SubscribeMe[i].name,
          img: this.api.LoginURL.concat(resp.data.SubscribeMe[i].img),
          uploadNum: resp2.data.ZYDocument_all_aggregate.aggregate.count || 0,
          // expires: resp.data.SubscribeMe[i].state==0?showTimeFormat(resp.data.SubscribeMe[i].expires):showTimeFormat(resp.data.SubscribeMe[i].expires)+' 已过期',
          expires: showTimeFormat2(resp.data.SubscribeMe[i].expires),
          type:
            resp.data.SubscribeMe[i].subscribe_type.search("一次性") > -1
              ? resp.data.SubscribeMe[i].subscribe_type.split(" +")[0]
              : resp.data.SubscribeMe[i].subscribe_type,
          concernNum: resp.data.SubscribeMe[i].concernNum,
        });
      }
    },

    //我订阅的主题
    async myDyZt(){
      let resp = await QUERYED(
        "post",
        "",
        "UserAttentionZy(where:{userId:{_eq:"+this.$store.state.userinfo.id+"},type :{_eq:\"1\"}}) {zyId,id}"
      );
      if(resp.success){
        let arr = []
        for(let item of resp.data.UserAttentionZy){
          let res = await QUERYED(
            "post",
            "",
            "Theme(where:{id:{_eq:"+item.zyId+"}}){id content title img }"
          );
          if(res.success){
            let obj = res.data.Theme[0]
            obj.tableId = item.id
            obj.img = api.LoginURL+obj.img
            arr.push(res.data.Theme[0])
          }
        }
        this.ztList = arr
      }
    },

    //取消订阅
    async tapQxDy(item,name){
      let res = await DELETE(
        "post",
        "",
        "delete_UserAttentionZy(where:{id:{_eq:"+(name=='myDyGjz'?item.id:item.tableId)+"}}){affected_rows}"
      );
      console.log(res,1);
      if(res.success){
        this[name]()
        this.$message.success({
          message: "取消订阅成功!",
          duration: 1500,
          center: true,
        });
      }
    },

    //页数改变时
    getList(e){
      this.page = e
      // console.log(e,123456);
      this.myDyZy()
    },

    //我订阅的资源
    async myDyZy(){
      let userId = this.$store.state.userinfo.id
      let page = this.page - 1
      let limit = this.limit
      let resp = await QUERYED(
        "post",
        "",
        "UserAttentionZy(limit:"+limit+",offset:"+page*limit+",where:{userId:{_eq:"+userId+"},type :{_eq:\"2\"}}) {zyId,id}"
        +" UserAttentionZy_aggregate(where:{userId:{_eq:"+userId+"},type :{_eq:\"2\"}}){aggregate{count}}"
      );
      if(resp.success){
        let arr = []
        this.count = resp.data.UserAttentionZy_aggregate.aggregate.count
        for(let item of resp.data.UserAttentionZy){
          let res = await QUERYED(
            "post",
            "",
            "ZYHotDocument(where:{id:{_eq:"+item.zyId+"}}){id img name download level}"
            // download id img name  score  read level
          );
          if(res.success){
            let obj = res.data.ZYHotDocument[0]
            obj.tableId = item.id
            obj.img = api.LoginURL+obj.img
            arr.push(obj)
          }
        }
        this.zyList = arr
      }
    },


    //我订阅关键字
    async myDyGjz(){
      let userId = this.$store.state.userinfo.id
      // userId = 883150
      let page = this.pagel - 1
      let limit = this.limitl
      let resp = await QUERYED(
        "post",
        "",
        "UserAttentionZy(limit:"+limit+",offset:"+page*limit+",where:{userId:{_eq:"+userId+"},type :{_eq:\"3\"}}) {id keyword}"
        +" UserAttentionZy_aggregate(where:{userId:{_eq:"+userId+"},type :{_eq:\"3\"}}){aggregate{count}}"
      );
      if(resp.success){
        this.countl = resp.data.UserAttentionZy_aggregate.aggregate.count
        this.lableList = resp.data.UserAttentionZy
      }
    },


    cancelSubs(id) {
      this.cancelUserID = id;
      this.dialogVisibleSubs = true;
    },
    async confirmCancelSubs() {
      this.cancelSubsLoading = true

      let resp = await UPDATES(
        "post",
        "",
        "update_SubscribeUserInfo(where:{id:{_eq:" +
          this.cancelUserID +
          "}},_set:{subscribe_state:" +
          1 +
          "}) {affected_rows}"
      );
      if (resp.data.update_SubscribeUserInfo.affected_rows == 1) {
        this.show3 = false;
        this.$message.success({
          message: "取消订阅成功!",
          duration: 1500,
          center: true,
        });
      } else {
        this.$message.error({
          message: "服务器繁忙,请稍后再试~",
          duration: 1500,
          center: true,
        });
      }

      this.cancelSubsLoading = false;
      this.dialogVisibleSubs = false;
      this.mySubc();
    },
    handleClose(done) {
      this.dialogVisible1 = false;
    },
  },
};
</script>

<style scoped>
/* public css */
ul {
  padding-inline-start: 10px;
}
.font14 {
  font-size: 14px;
}
.text-right {
  text-align: right;
}
.color-black {
  color: #000;
}
/*private css*/
.title {
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 1em;
}
.sort {
  margin-block-end: 0em;
}
.contain img {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  vertical-align: 0;
}
.contain li {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}
.info {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  font-size: 12px;
  width: 197px;
}
.info span {
  display: block;

  margin-bottom: 12px;
}
.info span font {
  color: black;
}
.info.info-r {
  width: 2.10294rem;
  padding-top: 31px;
  position: relative;
}
.el-dialog__header {
  text-align: center;
  padding: 20px 20px 10px;
}

.info-r.info span {
  /* padding-top: 26px; */
  display: block;
}
.info-r.info span:nth-child(2) {
  right: 0;
  bottom: 0;
  position: absolute;
}
.cancel-sub {
  margin-left: 157px;
  color: #0084ff;
  cursor: pointer;
}



.theme_list_item{
  padding:10px 0;
  display: flex;
  border-bottom: 1px solid #999;
}
img.list_item_img{
  width: 270px;
  height: 170px;
  border-radius: 0;
}
.list_item_right{
  flex: 1;
  margin-left: 10px;
  height: 170px;
}
.item_right_title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item_right_title>span{
  font-size: 20px;
  color: #000;
  cursor: pointer;
}
.item_right_title>div{
  width: 80px;
  height: 35px;
  background-color: rgb(14,134,246);
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  color: #ffffff;
  font-size: 16px;
}
.item_right_title>div:hover{
  opacity: 0.9;
  cursor: pointer;
}
.item_right_comment{
  width: 480px;
  height: 115px;
  margin-top: 10px;
  font-size: 14px;
  text-align: justify;
  color: #333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}


.dyZyList{
  padding:10px 0;
  display: flex;
  border-bottom: 1px solid #999;
}
img.dyZyList_item_img{
  width: 100px;
  height: 63px;
  border-radius: 0;
}
.dyZyList_item_right{
  flex: 1;
  margin-left: 10px;
  /* height: 170px; */
}
.dyZyList_item_right_title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dyZyList_item_right_title>span{
  font-size: 16px;
  color: #000;
}
.dyZyList_item_right_title>div,.qxdyBtn{
  width: 80px;
  height: 35px;
  background-color: rgb(14,134,246);
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  color: #ffffff;
  font-size: 16px;
}
.dyZyList_item_right_title>div:hover,.qxdyBtn:hover{
  opacity: 0.9;
  cursor: pointer;
}
.dyZyList_item_right_comment{
  width: 480px;
  /* height: 115px; */
  margin-top: 10px;
  font-size: 14px;
  text-align: justify;
  color: #333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}
.fxSb{
  justify-content: space-between;
  align-items: center;
}
</style>
